import { Component, Input, OnInit } from '@angular/core';

@Component({
  	selector: 'top-bar',
  	templateUrl: './top-bar.comp.html',
  	styleUrls: ['./top-bar.comp.scss'],
})
export class TopBarComp implements OnInit {
	@Input() lang: string;
	@Input() fade;
	@Input() tab;
	@Input() form: any;

	languages = [
		{code: 'en', icon: 'GB', name: 'English'},
		{code: 'zh', icon: 'CH', name: '中文'},
		{code: 'de', icon: 'DE', name: 'Deutsche'},
		{code: 'es', icon: 'ES', name: 'Español'},
		{code: 'fr', icon: 'FR', name: 'Français'},
		{code: 'sv', icon: 'SW', name: 'Svenska'}
	];
	langCount = Math.min(this.languages.length, 4.5);

  	constructor() { }

  	ngOnInit() {}

	// TODO createMenu(name) {
	// 			$ionicPopover.fromTemplateUrl('templates/topbar-'+name+'.html', {scope:scope})
	// 			.then(function(menu) {
	// 				scope['topbar'+name] = menu;
 	//    			});	
	// 		};

	// Get menu popovers ready
	// createMenu('language');
	// createMenu('menu');
			
	// TODO showMenu = function(menuName, $event) {
	//	scope['topbar'+menuName].show($event); // Show menu
	//};
			
	// scope.chooseTab = function(chosen) {
	// 			scope.tab = chosen;
	// 			if (chosen=='activity') {scope.startActivity()};
	// 			scope['topbarmenu'].hide();
	// 		};
	// 		scope.defaultTab = 'gallery';
	// 		scope.tabMatch = function(tab, match) {
	// 			if (_.isUndefined(tab)) {
	// 				return tab == scope.defaultTab;
	// 			} else {
	// 				return tab == match;
	// 			};
	// 		};

			// scope.startActivity = function() { 
			// 	// Used to initialise the map in Activity otherwise the dimensions are a problem in ng-show
			// 	$rootScope.$broadcast('map:portrait');
			// 	return 'activity';
			// };

	// TODO chooseLang = function(lang) { // A language was selected. Switch and close the dialogue
	// 	$translate.use(lang); scope.language = lang;
	// 	$rootScope.$broadcast('language.changed',lang); // Tell everyone to change language
	// 	scope['topbarlanguage'].hide();
	// };

	// 		scope.$on('language.changed', function(event, lang){
	// 			scope.lang = lang;
	// 			// console.log('topbar lang now:',scope.lang);
	// 		});
}