import { Component, Input, Output, OnInit, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AnimateService } from '../../services/animate.service';
import { DebugService } from '../../services/debug.service';
import { DeviceService } from '../../services/device.service';
import { EventsService } from '../../services/events.service';
import * as _ from 'underscore';

@Component({
  	selector: 'show-description',
  	templateUrl: './show-description.comp.html',
  	styleUrls: ['./show-description.comp.scss'],
})
export class ShowDescriptionComp implements OnInit {
	@Input() lang: boolean;
	@Input() form: any;
	@Output() trig = new EventEmitter<any>(); // Deleted or changed primary

	@ViewChild('trigger', {read: ElementRef, static: true}) trigger: ElementRef;

  	constructor(
  		public device:DeviceService
  	) { }

  	ngOnInit() {}

	ngAfterViewInit() {
  		this.getTrigger(this.trigger);
  	}

  	getTrigger(trigger) {
  		let fn = {name:'getTrigger', lg:true}; // Console info
  		setTimeout(() => {
  			this.trig.emit({
	  			seg: 'gallery',
	  			trigPx: this.device.getOffset(trigger)
	  		});
	  	}, 500)
  	}
}