import { Component, OnInit, Input } from '@angular/core';

@Component({
  	selector: 'show-facilities',
  	templateUrl: './show-facilities.comp.html',
  	styleUrls: ['./show-facilities.comp.scss'],
})
export class ShowFacilitiesComp implements OnInit {
	@Input() form: any;
	@Input() lang;

	columns: number;

  	constructor() {
  		
  	}

  	ngOnInit() {
  		this.columns = Math.floor(this.form.width / 230);
  	}
}