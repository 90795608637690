import { Component, OnInit, Input } from '@angular/core';

@Component({
  	selector: 'fac-item',
  	templateUrl: './fac-item.comp.html',
  	styleUrls: ['./fac-item.comp.scss'],
})
export class FacItemComp implements OnInit {
	@Input() icon: string; 
	@Input() fcontent: string;
	@Input() size;
	@Input() xlang;
	@Input() noTop;

  	constructor() { }

  	ngOnInit() {}

}