import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

@Injectable({
  	providedIn: 'root'
})
export class SettingsService {
	setting = {price: {}};
	price = {from: '', to: ''};
  	
  	constructor(public ngxTrans:TranslateService) { }

  	read(lang) { // Read the settings.json file
  		this.ngxTrans.use('settings');
  		return this.ngxTrans.get(['setting']).toPromise().then((setting) => {
  			this.setting = setting;
  			_.each(_.keys(setting.setting), (key) => { // Write them as individual local variables
  				this[key] = setting.setting[key];
  			})
  			this.ngxTrans.use(lang);
  			return lang;
  		});
  	}
}
