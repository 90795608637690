import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { } from 'googlemaps';
import { TranslateService } from '@ngx-translate/core';
import { SecureService } from '../../services/secure.service';
import { TranslationService } from '../../services/translation.service';
import * as _ from 'underscore';

@Component({
  	selector: 'show-activity',
  	templateUrl: './show-activity.comp.html',
  	styleUrls: ['./show-activity.comp.scss'],
})
export class ShowActivityComp implements OnInit {
	@Input() form: any;
	@Input() lang;
	// @Input() map: any;

	activities = this.loadActivities();
	icon = {
		Active: {shape:'bicycle', colour:'purple'},
		Restaurants: {shape:'restaurant', colour:'green'},
		Shops: {shape:'basket', colour:'blue'},
		Other: {shape:'information', colour:'red'},
		Walks: {shape:'walk', colour:'orange'}
	};
	chosen: any;
	colMap: number;
	markers;
	showDir;
	showSteps;
	cats;
	mapObj: google.maps.Map;
	//directionsService;
	//directionsDisplay;

	@ViewChild('map', {static: false}) mapEl: ElementRef;
	// @ViewChild('mapp', {read: ElementRef, static: true}) mapp: ElementRef;

  	constructor(
  		public secure:SecureService, public translate:TranslateService, public trans:TranslationService
  	) {
		// scope.$on('map:landscape', function() {mapInit(scope.map)});
		// scope.$on('map:portrait', function() {mapInit(scope.map)});
		// scope.$on('language.changed', function(event, lang){
			// Need to refresh map but this is not simple. Jump to Gallery instead
		// 	if (scope.$parent.tab == 'activity') {scope.$parent.tab = 'gallery'};
		// });

  	}

  	ngOnInit() {
  		if (this.form.factor == "landscape") {
			if (this.form.factor == 'wide') {this.colMap = 8} else {this.colMap = 6};
		} else {
		}

		setTimeout(() => {
			const mapProperties = {
    	    	center: new google.maps.LatLng(35.2271, -80.8431), // Centred on St Ives
        		zoom: 15,
        		mapTypeId: google.maps.MapTypeId.ROADMAP
   			};
   			this.mapObj = new google.maps.Map(this.mapEl.nativeElement, mapProperties);
   			this.mapInit(this.mapObj);
   		}, 100)
  	}

	flip(cat) {
		this.chosen[cat] = !this.chosen[cat];
		const add = this.chosen[cat]; // Add or remove?
		//if (!this.chosen[cat]) {debugger; /*toMap = null*/};
		let count = 0;
		_.each(this.activities, (act) => { // Set cat markers to toMap
			// if (act.id==10) debugger;
			if (!!act.types && act.types.indexOf(cat)>=0) { // Correct category?
				act.marker.setMap(add ? this.mapObj : null); // Set to show on the current map or not (null)
				count = count + 1
				act.marker.labelContent = count;
			}
		})
	}

	catMatch(act, chose) { // Is this cat in the activity?
		if (!act.types || !chose) {
			return false;
		} else {
			return _.some(act.types, function(cat) {return !!chose[cat] && chose[cat]});
		}
	}

	isTrue(bool) {return _.isBoolean(bool) && bool};
	nonBlank(text) {return !_.isUndefined(text) && text!=''};

	buildIW(a) {
		let colour = '; color:'+this.icon[a.types[0]].colour;
		let titleStyle = ' id="id'+a.id+'title" style="padding-bottom:2px; font-weight:bold; font-size:14px'+colour+'"';
		let iconStyle = ' style="margin:2px 5px -1px 0px '+colour+'"';
		let itemStyle = ' style="font-size:12px; border:none; xpadding:0 5px 0 40px" ';
		
		if (!!a.walk) {
			let name = this.translate.instant('ACT.WALK.'+a.walk);
			let dValue = a.difficulty+'/5 '+this.translate.instant('ACT.WALK.'+a.difficulty); 
			let diff = this.translate.instant('ACT.WALK.DIFFICULTY')+': '+dValue;
			let sturdy = this.translate.instant('ACT.NOTES.STURDY');
			let steps = this.translate.instant('ACT.NOTES.STEPS');
			let dogs = this.translate.instant('ACT.NOTES.DOGSOK');
			let inclines = this.translate.instant('ACT.NOTES.INCLINES');
			let show = this.translate.instant('ACT.WALK.SHOWDIR');

			let title = '<div'+titleStyle+'>'+name+' '+a.detail+'</div>';
			let icon = '<ion-icon slot="start"' + iconStyle + ' name="', iconEnd = '"></ion-icon>';
			
			// Add the directions on/off chekbox
			let check = '<ion-item lines="none" class="iw-checkbox" style="--min-height:40px; --highlight-height:0">';
			let checkEnd = '</ion-item>';
			//let change = '  (ionChange)="directions(actLocate('+a.id+'),showDir,false)"';
			let margin = 'margin-top:0px; margin-bottom:0px; ';
			let checkStyle = 'slot="start" style="'+margin+'--background-checked: orange; --border-color-checked: orange;" ';
			let label = '<ion-label style="'+margin+'">'+show+'</ion-label>';
			let id = ' id="id' + a.id + '" ';
			let box = '<ion-checkbox '/*[(ngModel)]="showDir"'*/ + id + checkStyle /*+ change*/+'></ion-checkbox>';
			let labelEnd = '<span class="iw-label">'+show+'</span></ion-label>';
			let dir = check+label+box+checkEnd;

			// let itemStyle = ' style="font-size:12px;border:none;padding:0 5px 0 40px" ';
			let itemHref = ' href="http://'+a.url+'" target="_blank" ';
			let itemMailto = ' href="mailto:'+a.email+'" target="_blank" ';
			let item = '<div class="ion-text-wrap"'+itemStyle+'>', itemEnd = '</div>';
			let itemAUr = '<div><a'+itemHref+'class="ion-text-wrap"'+itemStyle+'>'; 
			let itemAEnd = '</a></div>';
			let itemAEm = '<div><a'+itemMailto+'class="ion-text-wrap"'+itemStyle+'>';
			
			// Add in the walk difficulty
			let di = ''; if (this.nonBlank(a.difficulty)) 
				{di = item+icon+'trending-up'+iconEnd+diff+itemEnd}; 
			
			// Add in any alerts
			let alArray = [], alerts = '';
			if (this.isTrue(a.notes.sturdyFootwear)) {alArray.push(sturdy)};
			if (this.isTrue(a.notes.steps)) {alArray.push(steps)};
			if (this.isTrue(a.notes.inclines)) {alArray.push(inclines)};
			if (alArray.length>0) { // Got at least 1 alert
				alerts = item + icon+'alert'+iconEnd + alArray.join('. ') +itemEnd;
			}
			
			// Add in any dog info
			let dog = ''; if (this.isTrue(a.notes.dogsOK)) 
				{dog = item+icon+'paw-outline'+iconEnd+dogs+itemEnd};

			// let dir = ''; 
			if (this.isTrue(a.directions)) {
				let click = '(click)="dir(dirs)"';
				// dir = check+label+input+labelEnd+checkEnd;
				//dir = '<button class="button icon-left ion-star" '+click+'>Directions</button>'
			} else {
				dir = '';
			};
			let big = '*ngIf="!showDir"';
			return '<div>'+title+'<div '+big+'class="list iw-details">'+di+alerts+dog+'</div>'+dir+'</div>';
		} else {
			// colour = this.icon[a.types[0]].colour;
			let title = '<div'+titleStyle+'>'+a.name+'</div>';
			let icon = '<ion-icon slot="start"' + iconStyle + 'name="', iconEnd = '"></ion-icon>';
			// let itemStyle = ' style="font-size:12px;border:none;xpadding:0 5px 0 40px" ';
			let itemHref = ' href="http://'+a.url+'" target="_blank" ';
			let itemMailto = ' href="mailto:'+a.email+'" target="_blank" ';
			let item = '<div class="ion-text-wrap"'+itemStyle+'>', itemEnd = '</div>';
			let itemAUr = '<div><a'+itemHref+'class="ion-text-wrap"'+itemStyle+'>'; 
			let itemAEnd = '</a></div>';
			let itemAEm = '<div><a'+itemMailto+'class="ion-text-wrap"'+itemStyle+'>';
			let ph = ''; if (this.nonBlank(a.phone)) {ph = item+icon+'call'+iconEnd+a.phone+itemEnd};
			let ad = ''; if (this.nonBlank(a.address)) {ad = item+icon+'home'+iconEnd+a.address+itemEnd};
			let ur = ''; if (this.nonBlank(a.url)) {ur = itemAUr+icon+'arrow-redo'+iconEnd+a.url+itemAEnd};
			let em = ''; if (this.nonBlank(a.email)) {em = itemAEm+icon+'mail'+iconEnd+a.email+itemAEnd};
			return '<div>'+title+'<div class="list iw-details">'+ph+ad+ur+em+'</div></div>';
		};
	};

	centreDelay(lat, lon, zoom, map) {
		setTimeout(() => {map.setCenter(new google.maps.LatLng(lat, lon), zoom)}, 1000);
	};

	directions(act, steps) { // Ask google for the directions
		let directionsService = new google.maps.DirectionsService();
		console.log('directions: act.showDir:', act.showDir);
		if (act.showDir) {
			// If we are turning directions on then make sure only one route is show at a time
			_.each(this.activities, (a) => {a.showDir = false}); // Set all to false so we don't end up with multiple directions outes drawn on the map
			act.showDir = true;// Now set this one to true again

			let ways = _.map(act.waypoints, function(w){
				return {location:new google.maps.LatLng(w.lat,w.lng), stopover:true};
			});
			
			directionsService.route({
			    origin: new google.maps.LatLng(50.216316, -5.479095),
			    destination: new google.maps.LatLng(act.lat, act.lng),
			    waypoints: ways,
			    travelMode: google.maps.TravelMode.WALKING,
			 }, (response, status) => {
			    if (status === google.maps.DirectionsStatus.OK) {
			    	act.dirRend = new google.maps.DirectionsRenderer();
			    	act.dirRend.setMap(this.mapObj);
			    	act.dirRend.setOptions({suppressMarkers:true, polylineOptions:{strokeColor:'orange'}});
			      	act.dirRend.setDirections(response);
			      	act.dirRend.setPanel(document.getElementById('steps'));
			      	console.log('directions 1: act.dirRend:', act.dirRend);
			    } else {
			      	window.alert('Directions request failed due to ' + status);
			    }
			});
		} else {
			console.log('directions 2: act.dirRend:', act.dirRend);
			act.dirRend.setMap(null);	// Remove the map directions
			this.showSteps = false;		// Set the step by step toggle to off
			//directionsRenderer.setPanel(); 	// Turn off step by step
		}
	};
			
	actLocate = function(target) {
		return _.find(this.activities, function(a){return a.id==target});
	};

	mapInit(mapObj) {
		let atBlueLoc = new google.maps.LatLng(50.216637, -5.479010);
		let atBlue = new google.maps.Marker({
			title: "Atlantic Blue",
			position: atBlueLoc, //{lat:50.216316, lng:-5.479095},
			animation: google.maps.Animation.DROP, draggable: false,
			icon: 'assets/img/map/AtBlue.png',
			zIndex: 200,
			map: mapObj
		});

		let types = [];
		this.markers = [];
		let iw = new google.maps.InfoWindow({content: "Holding..."});
		this.showDir = false;
		
		_.each(this.activities, (act) => { // Create a reusable marker and infoWindow for each activity type
			types = types.concat(act.types);
			let marker = new google.maps.Marker({
				position: {lat:act.lat, lng:act.lng},
				icon: {
					url:'assets/img/map/'+act.types[0]+'.png',
					labelOrigin: new google.maps.Point(10,10)
				},
				label: {
					color:'white',
					fontSize: '12px',
					fontWeight: 'bold',
					text:(_.filter(types, (t) => {return t==act.types}).length).toString()
				},
				title: act.name,
				map: mapObj
			});

			let info = this.buildIW(act);

			google.maps.event.addListener(marker, "click", (e) => {
				const infoWindow = new google.maps.InfoWindow({
        			content: info
      			});
      			infoWindow.open(marker.getMap(), marker);

				if (act.types = ['Walks'] && !!act.directions) { // If it's a walk add a 'directions' button' event
					google.maps.event.addListener(infoWindow, 'domready', (y) => {
						document.getElementById("id"+act.id.toString()).addEventListener("click", (box) => {
        					act.showDir = !act.showDir;
        					this.directions(this.actLocate(act.id),false);
    					});
					});
				}
			});
	
			act.marker = marker;
		});
		this.cats = _.uniq(types);
		
		_.each(this.activities, (act) => { // Turn off all icons except Home and Restaurants
			if (!!act.types && act.types.indexOf('Restaurants')<0) {act.marker.setMap(null)};
		});
		this.chosen = {Restaurants: true};

		// Intelligently centre the map
		if (this.form.factor == "landscape") {
			this.centreDelay(50.215, -5.485, 15, mapObj);
		} else {
			if (this.form.factor == "portrait") { 
				if (this.form.size == "phone") { this.centreDelay(50.216, -5.479, 14, mapObj) }
				else { this.centreDelay(50.215, -5.480, 14, mapObj) };
			} else {
				this.centreDelay(50.220, -5.491, 15, mapObj)
			}
		};
		//console.log(mapObj.center.J,mapObj.center.M);
	};

	loadActivities() {
		let acts = [ 
			// Temporary markers initially hidden
			// Icons will be taken from the first entry in 'types'
			// Restaurants
			{
				name:'The Tea Room', types:['Restaurants'],
				lat:50.215465, lng:-5.477224,
				address:'1 Wharf House, The Wharf, St Ives, Cornwall, TR26 1PG',
				url:'thetearoomstives.co.uk',
				phone:'01736 794325', email:'tamsinthompson@hotmail.com'
			},{
				name:'Bumbles Tea Room', types:['Restaurants'],
				lat:50.214941, lng:-5.480751,
				address:'The Digey, Saint Ives, TR26 1HR',
				url:'',
				phone:'01736 797977', email:''
			},{
				name:'Porthgwidden Beach Cafe', types:['Restaurants'],
				lat:50.216964, lng:-5.476824,
				address:'Porthgwidden Beach, St Ives, TR26 1PL',
				url:'www.porthgwiddencafe.co.uk',
				phone:'01736 796791', email:''
			},{
				name:'Porthmeor Beach Café', types:['Restaurants'],
				lat:50.215307, lng:-5.482048,
				address:'Porthmeor Beach, St Ives, TR26 1JZ',
				url:'www.porthmeor-beach.co.uk',
				phone:'01736 793366', email:'porthmeor@live.co.uk'
			},{
				name:'The Sloop Inn', types:['Restaurants'],
				lat:50.215163, lng:-5.479085,
				address:'Back Lane, St Ives, TR26 1LP',
				url:'www.sloop-inn.co.uk',
				phone:'01736 796584', email:'info@sloop-inn.co.uk'
			},{
				name:'Blas Burgerworks', types:['Restaurants'],
				lat:50.211475, lng:-5.478979,
				address:'The Warren, St Ives, TR26 2EA',
				url:'www.blasburgerworks.co.uk',
				phone:'01736 797272', email:'info@blasburgerworks.co.uk'
			},{
				name:'Seafood Cafe', types:['Restaurants'],
				lat:50.214064, lng:-5.480269,
				address:'45 Fore St, St Ives, TR26 1HE',
				url:'seafoodcafe.co.uk',
				phone:'01736 794004', email:''
			},{
				name:'The Balancing Eel', types:['Restaurants'],
				lat:50.215069, lng:-5.479300,
				address:'Back Ln, St Ives, TR26 1LR',
				url:'www.facebook.com/BalancingEel',
				phone:'01736 796792', email:''
			},{
				name:'The Hidden Kitchen & Dining', types:['Restaurants'],
				lat:50.211659, lng:-5.479360,
				address:'The Masonic Lodge, St. Andrews Street, Saint Ives, TR26 1AH',
				url:'hiddenkitchenstives.co.uk',
				phone:'01736 795940', email:''
			},

			{
				name:'Tourist Information Centre', types:['Other'],
				lat:50.211638, lng:-5.480302,
				address:'The Guildhall, Street-An-Pol, St Ives, TR26 2DS',
				url:'www.visitstives.org.uk',
				phone:'0905 252 2250', email:''
			},

			{
				name:'St Ives Surf School', types:['Active'],
				lat:50.214842, lng:-5.482450, 
				address:'Porthmeor Beach, St Ives, TR26 1JZ',
				url:'www.stivessurfschool.co.uk',
				phone:'01736 793 938', email:'info@stivessurfschool.co.uk'
			},{
				name:'St Ives Watersports', types:['Active'],
				lat:50.209295, lng:-5.477096, 
				address:'Porthminster Beach Townside, St Ives, TR26 2EB',
				url:'www.stivessurfschool.co.uk',
				phone:'01736 793 938', email:'info@stivessurfschool.co.uk'
			},{
				name:'Kitesurfing St Ives', types:['Active'],
				lat:50.215463, lng:-5.483150, 
				address:'St Ives, TR26 1FB',
				url:'www.kitesurfingstives.co.uk',
				phone:'07720 438453', email:'info@windydayz.co.uk'
			},{
				name:'Go By Cycle', types:['Active'],
				lat:50.518728, lng:-4.838212, 
				address:'Eddystone Road, Wadebridge PL27 7AL',
				url:'www.cornwallbikehire.co.uk',
				phone:'07950 774067', email:'info@gobycycle.co.uk'
			},{
				name:'St Ives Fishing Trips', types:['Active'],
				lat:50.212941, lng:-5.479482, 
				address:'Outside The Lifeboat Station, St Ives TR26 2JB',
				url:'www.stivesfishingtrips.com',
				phone:'07747 493101', email:'samnarbett@icloud.com'
			},{
				name:'St Ives Sailing Club', types:['Active'],
				lat:50.215394, lng:-5.479737, 
				address:'Sloop Inn Car Park, St Ives',
				url:'www.stivessailingclub.com',
				phone:'07747 493101', email:'secretary@stivessailingclub.com'
			},{
				name:'St Ives Self Drive Motorboats', types:['Active'],
				lat:50.213576, lng:-5.479774, 
				address:'Harbour, The Wharf, St Ives, TR26 1LP',
				url:'stivesboatrides.co.uk',
				phone:'07824 633447', email:''
			},

			{
				name:'I Should Coco', types:['Shops'],
				lat:50.213699, lng:-5.480512, 
				address:'39 Fore Street, St Ives, TR26 1HE',
				url:'www.ishouldcoco.co.uk',
				phone:'01736 798756', email:'chocolatier@ishouldcoco.co.uk'
			},{
				name:'Co-Operative', types:['Shops'],
				lat:50.215637, lng:-5.480873,
				address:'13-14 Tregenna Place, Saint Ives, Cornwall TR26 1SD',
				url:'www.co-operative.coop/find-us/?q=st%20ives&lat=50.20838579999999&long=-5.490886400000022&filters=Food&options=',
				phone:'01736 7960926', email:''
			},{
				name:'Norway Stores', types:['Shops'],
				lat:50.215684, lng:-5.479719,
				address:'32 Back Rd W, St Ives, TR26 1NE',
				url:'www.norwaystores.co.uk',
				phone:'01736 794657', email:''
			},{
				name:'Kudos', types:['Active'],
				lat:50.214755, lng:-5.479865, 
				address:'71-73 Fore St, Saint Ives TR26 1HW',
				url:'www.kudos1.co.uk',
				phone:'01736 798586', email:'website@kudos1.co.uk'
			},

			{
				walk:'CIRCULAR', detail:'A', types:['Walks'],
				lat:50.216216, lng:-5.479000,
				directions:true, 
				notes:{sturdyFootwear:true, inclines:true, steps:true, dogsOK:true},
				waypoints:[ {lat:50.216338,lng:-5.504233}, {lat:50.206244,lng:-5.501488} ],
				difficulty:3, dist:3.7,
				address:'', url:'', phone:'', email:''
			},{
				walk:'CIRCULAR', detail:'B', types:['Walks'],
				lat:50.216216, lng:-5.479000,
				directions:true, 
				notes:{sturdyFootwear:false, inclines:true, steps:true, dogsOK:true},
				waypoints:[
					{lat:50.209455,lng:-5.491085}, 
					{lat:50.202808,lng:-5.480527},
					{lat:50.204236,lng:-5.471086}
				],
				difficulty:2, dist:1.4,
				address:'', url:'', phone:'', email:''
			},{
				walk:'TOSTATION', detail:'', types:['Walks'],
				lat:50.209045, lng:-5.478007,
				directions:true, 
				notes:{sturdyFootwear:false, inclines:false, steps:false, dogsOK:false},
				waypoints:[],
				difficulty:1, dist:0.7,
				address:'Station Road, Trelyon Avenue, St Ives, Cornwall, TR26 2BP', 
				url:'www.nationalrail.co.uk/stations/SIV/details.html', phone:'03457 000125', email:''
			},{
				walk:'TO', detail:'Smeatons Pier', types:['Walks'],
				lat:50.213607, lng:-5.476694,
				directions:true, 
				notes:{sturdyFootwear:false, inclines:false, steps:false, dogsOK:true},
				waypoints:[ {lat:50.218886, lng:-5.477791}, {lat:50.216675, lng:-5.475678} ],
				difficulty:2, dist:0.8,
				address:'', 
				url:'', phone:'', email:''
			},{
				walk:'TO', detail:'Lelant', types:['Walks'],
				lat:50.184259, lng:-5.436667,
				directions:true, 
				notes:{sturdyFootwear:true, inclines:true, steps:true, dogsOK:true},
				waypoints:[ {lat:50.194480, lng:-5.448769} ],
				difficulty:2, dist:3.7,
				address:'', 
				url:'', phone:'', email:''
			},{
				walk:'TO', detail:'Zennor', types:['Walks'],
				lat:50.195838, lng:-5.573890,
				directions:true, 
				notes:{sturdyFootwear:true, inclines:true, steps:true, dogsOK:true},
				waypoints:[ {lat:50.1954562, lng:-5.5740765} ],
				difficulty:4, dist:6,
				address:'', 
				url:'', phone:'', email:''
			}
		];
		_.each(acts, (act, n) => {
			act.id=n;
			act.showDir = false;
		});
		return acts;
	}
}
