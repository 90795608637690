import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../services/translation.service';
import { DeviceService } from '../../services/device.service';

@Component({
  	selector: 'activity',
  	templateUrl: './activity.comp.html',
  	styleUrls: ['./activity.comp.scss'],
})
export class ActivityComp implements OnInit {
	@Input() activity;
	@Input() colour;
	// @Input() confirm;
	@Input() count;
	// @Input() directions;
	@Input() icons;
	@Input() lang;
	@Input() thismap;

	open: boolean = false;
	canCall = this.device.callable();
	showSteps: boolean = false;
	showDir: boolean = false;
	dist: 'm'|'km' = this.ngxTranslate.instant('ACT.DIST');

  	constructor(
  		public device:DeviceService, public ngxTranslate:TranslateService
  	) { }

  	ngOnInit() {
  		this.dist = this.ngxTranslate.instant('ACT.DIST');
  	}

	steps(id, steps, open) { // Show step by step
		// if (steps && open) { // Only if the toggle is on and the walk is open
		// 	let stepsHTML = document.getElementById('steps'+id);
		// 	this.directions.setPanel(stepsHTML);
		// } else {
		// 	this.directions.setPanel();
		// }
	}

	openClose() { // Flip the open/close state of this activity
		this.open = !this.open;
		if (!this.open) {
			// this.directions.setPanel(); 	// Turn off step by step
			// this.directions.setMap(null);	// Remove the map directions
			// this.showSteps = false; this.showDir = false;
		}
	}

	miles(miles) {
		if (this.dist == 'm') {
        	return miles;
      	} else {
        	let km = miles * 1.609;
        	return Math.floor(km*10)/10;
      	}
	}
}