import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  	selector: 'lang-select',
  	templateUrl: './lang-select.comp.html',
  	styleUrls: ['./lang-select.comp.scss'],
})
export class LangSelectComp implements OnInit {
	
  	constructor(
  		public popoverCtrl: PopoverController
  	) {
  	}

  	ngOnInit() {
  	}

  	chooseLang(langCode) {
  		this.popoverCtrl.dismiss(langCode);
  	}
}