import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'more',
  templateUrl: './more.comp.html',
  styleUrls: ['./more.comp.scss'],
})
export class MoreComp implements OnInit {

  constructor() { }

  ngOnInit() {}

}