import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../services/device.service';
import { SettingsService } from '../../services/settings.service';
import { TranslationService } from '../../services/translation.service';


@Component({
  	selector: 'show-availability',
  	templateUrl: './show-availability.comp.html',
  	styleUrls: ['./show-availability.comp.scss'],
})
export class ShowAvailabilityComp implements OnInit {
	@Input() form: any;
	@Input() lang;

	canCall = this.device.callable();

  	constructor(
  		public device:DeviceService, public settings:SettingsService,
  		public translate:TranslationService, public ngxTrans:TranslateService
  	) { }

  	ngOnInit() {}
}