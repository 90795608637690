import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DebugService } from '../../services/debug.service';
import { EventsService } from '../../services/events.service';

@Component({
  	selector: 'desc-phone',
  	templateUrl: './desc-phone.comp.html',
  	styleUrls: ['./desc-phone.comp.scss'],
})
export class DescPhoneComp implements OnInit, AfterViewInit {
	@Input() head: string;
	@Input() lang;
	@Input() image;
	@Input() noTop: boolean;
	@Input() phone: boolean = false;
	@Input() teaser: string;

	open: boolean = false;
	more: boolean;

	@ViewChild('desc', {read: ElementRef, static: false}) desc: ElementRef;

	logFile = {name:this.constructor.name, lg:true}; // Log this module?

  	constructor(
  		public debug:DebugService, public events:EventsService
  	) {
  		this.events.subscribe('resized', () => {
  		});
  	}

  	ngOnInit() {}

  	ngAfterViewInit() {}

  	toggleOpen() {
  		let fn = {name:'toggleOpen', lg:true}; // Console info
  		this.debug.clog([this.logFile, fn], []);

  		this.open = !this.open;
  	}
}