import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

@Injectable({
  	providedIn: 'root'
})
export class TranslationService {
	languages = [
		{code: 'en', icon: 'GB', name: 'English'},
		{code: 'zh', icon: 'CH', name: '中文'},
		{code: 'de', icon: 'DE', name: 'Deutsche'},
		{code: 'es', icon: 'ES', name: 'Español'},
		{code: 'fr', icon: 'FR', name: 'Français'},
		{code: 'sv', icon: 'SW', name: 'Svenska'}
	];
	langCount = Math.min(this.languages.length, 4.5);

  	constructor(
  		public translate:TranslateService
  	) { }

  	getDefaultLanguage() {
    	let language = this.translate.getBrowserLang();
    	this.translate.setDefaultLang(language);
    	return language;
  	}
 
  	setLanguage(setLang) {
    	this.translate.use(setLang);
  	}

  	addLanguages(langs:string[]) {
  		this.translate.addLangs(langs);
  	}

  	getLanguages() {
  		let ngxLangs = this.translate.getLangs();
  		return _.filter(this.languages, (lan) => {return ngxLangs.indexOf(lan.code) >= 0});
  	}
}
