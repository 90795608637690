import { Injectable } from '@angular/core';
import { ToastController, Platform } from '@ionic/angular';
import * as _ from 'underscore';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class DebugService {
	
	constructor(
    public toastCtrl: ToastController, public plat: Platform
  ) {
  };

  announce(params) { // Announce the function and return the log info
    if (params.lg) {
      this.clog([params.lf, {name:params.lg, lg:true}], []);
      // this.debug.clog([this.logFile, fn], []]);
      // let fn = {name:'pageBad', lg:true}; // Console info
    }
  }

  clog(switches:any[], msgs?:any[]) { // Emit an intelligent console.log message
    // console.log('clog 1:', JSON.stringify(msgs));
    if (environment.log.me) {
      let fl = switches[0], fn = switches[1], sum = false, cond = true;
      if (switches.length >= 3) {sum = switches[2]};
      if (switches.length >= 4) {cond = switches[3]};
      if (sum || (cond && fl.lg && fn.lg)) {
        let prefix = fl.name+':'+fn.name;
        
        prefix = JSON.stringify(prefix);
        if (environment.log.type == 'xcode') {
          msgs = _.map(msgs, (msg) => {
            if (!_.isString(msg)) {return JSON.stringify(msg)} else {return msg};
          })
        };
        
        if (!msgs) {
          console.log(prefix);
        } else {
          switch(msgs.length) {
            case 0: console.log(prefix); break;
            case 1: console.log(prefix, msgs[0]); break;
            case 2: console.log(prefix, msgs[0], msgs[1]); break;
            case 3: console.log(prefix, msgs[0], msgs[1], msgs[2]); break;
            case 4: console.log(prefix, msgs[0], msgs[1], msgs[2], msgs[3]); break;
            default:
              if (msgs.length > 4) { 
                console.log(prefix, msgs[0], msgs[1], msgs[2], msgs[3], '...'); break;
              }
          }
        }
      }
    }
  }

  debug(fl:any, fn:any) {
    if (environment.log.me) {
      return fl.lg && fn.lg
    }
  }
}