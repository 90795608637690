import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  	selector: 'menu-select',
  	templateUrl: './menu-select.comp.html',
  	styleUrls: ['./menu-select.comp.scss'],
})
export class MenuSelectComp implements OnInit {
	
  	constructor(
  		public popoverCtrl: PopoverController
  	) {
  	}

  	ngOnInit() {
  	}

  	chooseSeg(menuItem) {
  		this.popoverCtrl.dismiss(menuItem);
  	}
}