import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

	constructor(
		public plat:Platform
	) {
	}

	// dimensionsById: function(id) { // Get dimension using element id
  //      console.log('id:',id);
  //      return $ionicPosition.position(angular.element(document.getElementById(id)));
  //  },
  
  //  dimensions: function(element) { // Get dimension using element id
  //      return $ionicPosition.position(element);
  //  },
  
  form() {
  	let height = this.plat.height(), width = this.plat.width();
    
    let ff = {factor: 'landscape', landscape:true, portrait:false, size:'wide', height:height, width:width, phone:false};

    if (width <= 1000/*was 1000*/) {
      ff.factor = "portrait"; ff.portrait = true; ff.landscape = false; ff.size = "medium";
    };
    if (width <= 401) {
      ff.size = "phone";  ff.phone = true;
    };
    return ff;
  }
}
