import { Component, Input, OnInit } from '@angular/core';

@Component({
  	selector: 'photo-banner',
  	templateUrl: './photo-banner.comp.html',
  	styleUrls: ['./photo-banner.comp.scss'],
})
export class PhotoBannerComp implements OnInit {
	@Input() content; 
	@Input() xminHeight; 
	@Input() form: any;
  	
  	constructor() { }

  	ngOnInit() {}

}
