import { Component, OnInit, Input } from '@angular/core';

@Component({
  	selector: 'photo-prev-next',
  	templateUrl: './photo-prev-next.comp.html',
  	styleUrls: ['./photo-prev-next.comp.scss'],
})
export class PhotoPrevNextComp implements OnInit {
	// [photo]="photo" [photos]="photos" open="open"

  	constructor() { }

  	ngOnInit() {}

  	prev(photo) {
		if (photo > 0) {
			return photo - 1; // Step back 1 photo
		}
	}

	next(photo, max) {
		if (photo < max) {
			return photo + 1; // Step forward 1 photo
		}
	}
}