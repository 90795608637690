import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'photo-box',
  	templateUrl: './photo-box.comp.html',
  	styleUrls: ['./photo-box.comp.scss'],
})
export class PhotoBoxComp implements OnInit {
	@Input() photos:any[];

  	constructor() { }

  	ngOnInit() {}

}