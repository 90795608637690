import { Injectable, ElementRef } from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { Animation } from '@ionic/core';
import { DebugService } from '../services/debug.service';
import _ from 'underscore';

@Injectable({providedIn: 'root'})
export class AnimateService {
  logFile = {name:this.constructor.name, lg:true}; // Log this module?
  
	constructor(
    public animationCtrl:AnimationController, public debug:DebugService
  ) {
  }

  blueBuild(bl:ElementRef) {
    console.log('blueBuild:', bl);
    const anBlue = this.animationCtrl.create().addElement(bl.nativeElement);

    const hideBlue = anBlue.fromTo('opacity', '1', '0');
    const anFor = this.animationCtrl.create('anFor')
      .addAnimation([hideBlue])
      // .afterStyles({color: 'transparent'})
      .iterations(1).duration(10000);

    const showBlue = anBlue.fromTo('opacity', '0', '1');
    const anRev = this.animationCtrl.create('anRev')
      .addAnimation([showBlue])
      // .afterStyles({color: 'black'})
      .iterations(1).duration(10000);

    return {forward:anFor, reverse:anRev};
  }

  blue(dir, an) {
    if (dir == 'forward') {
      // an.reverse.stop();
      console.log('for play');
      an.forward.play();
    } else {
      //an.forward.stop();
      console.log('rev play'); 
      an.reverse.play();
    }
  }

  test(at:ElementRef, blue:ElementRef) {
    this.animationCtrl.create()
      .addElement(at.nativeElement)
      .fromTo('left', '74px', '0px')
      .iterations(1)
      .duration(1000)
      .play()
  }

  titleBuild(at:ElementRef, blue1:ElementRef, blue2:ElementRef) {
    console.log('titleBuild: blue2:', blue2);
    let ms = 1500, animations = [];

    if (!!at) {animations.push(
      this.animationCtrl.create('atLeft').addElement(at.nativeElement).fromTo('left', '44px', '10px').delay(ms*0.5).duration(ms*0.5)
    )}
    if (!!blue1) {animations.push(
      this.animationCtrl.create('b1Right').addElement(blue1.nativeElement)
        .fromTo('left', '43px', '140px')
        .fromTo('opacity', '0', '1')
        .delay(ms).duration(ms*0.5)
    )}
    if (!!blue2) {animations.push(
      this.animationCtrl.create('b2Up').addElement(blue2.nativeElement)
        //.fromTo('top', '-6px', '-44px')
        .fromTo('transform', 'translateY(0px)', 'translateY(-44px)')
        .fromTo('opacity', '1', '0')
        .duration(ms*0.2)
    )}

    return animations; 
  }

  title(dir, ans) {
    _.each(ans, (an) => {
      console.log('playing',dir,an.id);
      if (an.id == 'b2Up' && dir == 'reverse') {
        setTimeout(() => {
          an.iterations(1).direction(dir).play();
        }, 500)
      } else {
        an.iterations(1).direction(dir).play();
      }
    });
  }
}