import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import _ from 'underscore';

@Injectable({providedIn: 'root'})
export class SecureService {
	
	constructor(
		public safe: DomSanitizer
	) {};

  	//--- SAFE - Sanitise things ---------------------------------------
  	safeNum(num) { // Sanitise a style number
	    if (!!num) {
	      	let num0 = Math.floor(num);
	     	return this.safe.bypassSecurityTrustStyle(num0.toString());
	    } else {
	      	return undefined;
	    }
	}
	  
	safeString(str) { // Sanitise a style string
	    return this.safe.bypassSecurityTrustStyle(str);
	}

	safeUrl(url) { // Sanitise a URL
	    return this.safe.bypassSecurityTrustUrl(url);
	}
}