import { Component, Input, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { DebugService } from '../../services/debug.service';
import { AnimateService } from '../../services/animate.service';

@Component({
  selector: 'at-blue', 
  templateUrl: './at-blue.comp.html',
  styleUrls: ['./at-blue.comp.scss'],
})
export class AtBlueComp implements OnInit, OnChanges { 
  @Input() form: any;
  @Input() show: 'both'|'at'|'bl' = 'both';
  @Input() size: number = 14;
  @Input() space: 'left'|'right'|'both'|'none' = 'none';
  @Input() container: 'title'|'default' = 'default'; 
  @Input() scrolling: string; 

  padL: number = 0;
  padR: number = 0;
  atlantic: boolean;
  blue: boolean;
  def: boolean;
  title: boolean;
  titleAnimation;
  factor: string = 'landscape'; // Assume no lhs menu for now 

  @ViewChild('atlantic', {read: ElementRef, static: false}) atlanticText: ElementRef;
  @ViewChild('blue1', {read: ElementRef, static: false}) blue1Text: ElementRef;
  @ViewChild('blue2', {read: ElementRef, static: false}) blue2Text: ElementRef;

  logFile = {name:this.constructor.name, lg:false}; // Log this module?

	constructor(
    public animate:AnimateService, public debug:DebugService
  ) {
    let fn = {name:'constructor', lg:true}; // Console info
  }

  ngOnChanges(changes) {
    let fn = {name:'ngOnChanges', lg:true}; // Console info
    this.debug.clog([this.logFile, fn], [changes]);

    if (!!changes.scrolling && !!changes.scrolling.currentValue) {
      if (!!this.titleAnimation) {
        console.log('at-blue: animate!:',changes.scrolling.currentValue, this.titleAnimation);
        this.animate.title(changes.scrolling.currentValue, this.titleAnimation);
      }
    }

    if (!!changes.form && !!changes.form.currentValue) {
      this.form = changes.form.currentValue;
      this.factor = this.form.factor; // Get a string to use as a class for the lhs menu offset
    }
  }

	ngOnInit() {
    this.title = this.container == 'title';
    this.def = this.container == 'default';
    this.atlantic = (this.show == 'both' || this.show == 'at');
    this.blue = (this.show == 'both' || this.show == 'bl');

		let px = 5; this.padL = 0, this.padR = 0;
		switch(this.space) {
			case 'left': this.padL = px; break;
			case 'right': this.padR = px; break;
			case 'both': this.padL = px; this.padR = px; break;
		}
	}

  ngAfterViewInit() {
    console.log('at-blue: ngAfter:');
    if (!!this.atlanticText && !!this.blue1Text) {
      console.log('at-blue: ngAfter: blue1');
      this.titleAnimation = this.animate.titleBuild(this.atlanticText, this.blue1Text, this.blue2Text);
    }

    if (!!this.blue2Text) {
      console.log('at-blue: ngAfter: blue2');
      this.titleAnimation = this.animate.titleBuild(this.atlanticText, this.blue1Text, this.blue2Text);
    }
  }
}