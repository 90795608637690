import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  	providedIn: 'root'
})
export class DeviceService {

  	constructor(
  		public plat:Platform
  	) {
  	}

  	callable() {
  		let canCall = this.plat.is('android') || this.plat.is('ios');
  		return canCall;
  	}

  	getOffset(el) {
  		if (!!el) {
	    	let elemRect = el.nativeElement.getBoundingClientRect();
	    	let bodyRect = document.body.getBoundingClientRect();
	    	let offset = elemRect.top - bodyRect.top;
  			return offset;
  		} else {
  			return 0;
  		}
	}
}