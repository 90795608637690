import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';

import { ActivityComp } from './activity/activity.comp';
import { AtBlueComp } from './at-blue/at-blue.comp';
import { DescPhoneComp } from './desc-section/desc-phone.comp';
import { DescPortraitComp } from './desc-section/desc-portrait.comp';
import { FacItemComp } from './fac-item/fac-item.comp';
import { LangSelectComp } from './lang-select/lang-select.comp';
import { LessComp } from './less/less.comp';
import { MenuSelectComp } from './menu-select/menu-select.comp';
import { MoreComp } from './more/more.comp';
import { PhotoBannerComp } from './photo-banner/photo-banner.comp';
import { PhotoBoxComp } from './photo-box/photo-box.comp';
import { PhotoPrevNextComp } from './photo-prev-next/photo-prev-next.comp';
import { PhotoSelectComp } from './photo-select/photo-select.comp';
import { ShowActivityComp } from './show-activity/show-activity.comp';
import { ShowAvailabilityComp } from './show-availability/show-availability.comp';
import { ShowGalleryComp } from './show-gallery/show-gallery.comp';
import { ShowFacilitiesComp } from './show-facilities/show-facilities.comp';
import { ShowDescriptionComp } from './show-description/show-description.comp';
import { TopBarComp } from './top-bar/top-bar.comp';

export function components() {
  return [
  	ActivityComp, AtBlueComp,
    DescPhoneComp, DescPortraitComp,
    FacItemComp,
    LangSelectComp, LessComp,
    MenuSelectComp, MoreComp,
    PhotoBannerComp, PhotoBoxComp, PhotoPrevNextComp, PhotoSelectComp,
    ShowActivityComp, ShowAvailabilityComp, ShowDescriptionComp, ShowFacilitiesComp, ShowGalleryComp,
    TopBarComp
  ]
}

@NgModule({
  imports: [
    IonicModule, CommonModule,
    TranslateModule, HttpClientModule
  ],
  declarations: [components()],
  exports: [components()]
})
export class ComponentsModule {}