import { Component, OnInit, Input } from '@angular/core';
import _ from 'underscore';

@Component({
  	selector: 'photo-select',
  	templateUrl: './photo-select.comp.html',
  	styleUrls: ['./photo-select.comp.scss'],
})
export class PhotoSelectComp implements OnInit {
	@Input() photo;
	@Input() photos: any[];
	@Input() highlight;
	@Input() stop;

	high = (!_.isUndefined(this.highlight) && this.highlight==true);
	
  	constructor() {	
  	}

  	ngOnInit() {}

	select(photo, ignore) {
		// If photo exists choose it
		if (photo >= 0 && photo <= this.photos.length-1) { // OK?
			return photo;
		} else {
			return ignore;
		}
	}
}