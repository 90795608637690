import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslatePipe} from '@ngx-translate/core';

import { ComponentsModule } from './components/components.module';
// import { DirectivesModule } from './directives/directives.module';
import { LangSelectComp } from './components/lang-select/lang-select.comp';
import { MenuSelectComp } from './components/menu-select/menu-select.comp';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [LangSelectComp, MenuSelectComp],
    imports: [
  	  BrowserModule, IonicModule.forRoot(), AppRoutingModule,
  	  ComponentsModule, 
      // DirectivesModule,
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        },
        defaultLanguage: 'en', useDefaultLang: true
      })
    ],
    providers: [
      StatusBar,
      SplashScreen,
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}