import { Component, Input, Output, OnInit, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DebugService } from '../../services/debug.service';
import { DeviceService } from '../../services/device.service';
import { EventsService } from '../../services/events.service';
import * as _ from 'underscore';

@Component({
  	selector: 'show-gallery',
  	templateUrl: './show-gallery.comp.html',
  	styleUrls: ['./show-gallery.comp.scss'],
})
export class ShowGalleryComp implements OnInit {
	@Input() box: boolean;
	@Input() form: any;
	@Output() trig = new EventEmitter<any>(); // Deleted or changed primary
	// landscape:"@", portrait:"@", lang:"=", box:"=", form:"="},

	touch = this.plat.is('android') || this.plat.is('ios');
	photos = _.range(22); photo:Number;

	@ViewChild('trigger', {read: ElementRef, static: true}) trigger: ElementRef;

	logFile = {name:this.constructor.name, lg:true}; // Log this module?

	constructor(
		public plat:Platform, public debug:DebugService, public device:DeviceService, public events:EventsService
	) {
		this.events.subscribe('resized', () => {this.getTrigger(this.trigger)})
	}

  	ngOnInit() {}

  	ngAfterViewInit() {
  		let fn = {name:'ngAfterViewInit', lg:true}; // Console info
  		this.getTrigger(this.trigger)
  	}

  	getTrigger(trigger) {
  		let fn = {name:'getTrigger', lg:true}; // Console info
  		setTimeout(() => {
  			this.trig.emit({
	  			seg: 'gallery',
	  			trigPx: this.device.getOffset(trigger)
	  		});
	  	}, 500)
  	}

	photoChoose(photo) {
		this.photo = photo;
		this.box = false;
	};

	stop() {
		debugger;
	}
}
