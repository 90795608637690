import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DebugService } from '../../services/debug.service';
import { EventsService } from '../../services/events.service';

@Component({
  	selector: 'desc-portrait',
  	templateUrl: './desc-portrait.comp.html',
  	styleUrls: ['./desc-portrait.comp.scss'],
})
export class DescPortraitComp implements OnInit, AfterViewInit {
	@Input() head: string;
	@Input() lang;
	@Input() image;
	@Input() noTop: boolean;
	@Input() teaser: string;

	open: boolean = false;
	more: boolean;

	@ViewChild('desc', {read: ElementRef, static: false}) desc: ElementRef;

	logFile = {name:this.constructor.name, lg:true}; // Log this module?

  	constructor(
  		public debug:DebugService, public events:EventsService
  	) {
  		this.events.subscribe('resized', () => {
  			this.open = false;
  			this.getMore();
  		});
  	}

  	ngOnInit() {}

  	ngAfterViewInit() {
  		this.getMore();
  	}

  	getMore() {
  		let fn = {name:'getMore', lg:false}; // Console info

  		setTimeout(() => {
  			this.more = (this.desc.nativeElement.scrollHeight - this.desc.nativeElement.clientHeight) > 5;
  		}, 200);
  	}

  	toggleOpen() {
  		let fn = {name:'toggleOpen', lg:true}; // Console info
  		this.debug.clog([this.logFile, fn], []);

  		this.open = !this.open;
  	}
}